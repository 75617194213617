.accordion {
    background: url('../../assets/images/accordionBg.png') no-repeat center center /cover;
    padding: 100px 0;

    &__title {
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        font-size: 50px;
        line-height: 70px;
        font-family: 'ElMessiri-SemiBold';
        margin-bottom: 50px;

        @media(max-width: 480px) {
            font-size: 40px;
            line-height: 50px;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:nth-of-type(1) {
            margin-bottom: 24px;
        }
    }

    &__item {
        background: rgba(198, 166, 106, 0.02);
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        padding: 40px 60px;


        @media(max-width: 480px) {
            padding: 25px 30px;
        }
    }

    &__btn {
        border: none;
        outline: none;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        font-size: 32px;
        line-height: 100%;
        color: #FFFFFF;
        font-family: 'ElMessiri-SemiBold';

        @media(max-width: 480px) {
            font-size: 25px;
        }

    }

    &__content {
        margin-top: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: 0.3s all ease-in-out;

        &::after {
            content: '';
            display: block;
            width: 100%;
            border-radius: 0px 0px 40px 40px;
            background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
            position: absolute;
            height: 7px;
            bottom: 0;
            left: 0;
        }

        &.active {
            margin-top: 40px;
            height: max-content;
            opacity: 1;
            overflow: visible;
        }

        p {
            font-size: 24px;
            line-height: 150%;
            color: #FFFFFF;
            font-family: 'Monrope-Regular';
        }
    }
}