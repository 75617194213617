$Monrope: ('Bold'),
('ExtraBold'),
('ExtraLight'),
('Light'),
('Medium'),
('Regular'),
('SemiBold');

$ElMessiri: ('Bold'),
('Medium'),
('Regular'),
('SemiBold');

@each $name in $Monrope {
    @font-face {
        font-family: 'Monrope-#{$name}';
        src: url(../assets/fonts/Manrope/Manrope-#{$name}.ttf);
    }
}

@each $name in $ElMessiri {
    @font-face {
        font-family: 'ElMessiri-#{$name}';
        src: url(../assets/fonts/El-Messiri/ElMessiri-#{$name}.ttf);
    }
}