.header {
    background: url('../../assets/images/headerBg.png') no-repeat;
    position: relative;
    min-height: 960px;
    display: flex;
    flex-direction: column;

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }

    &__cloud {
        position: absolute;
        bottom: 0px;
        left: 104px;

        @media(max-width: 768px) {
            display: none;
        }

    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }



    &__title {
        font-size: 64px;
        line-height: 70px;
        font-family: 'ElMessiri-Medium';
        color: #FFFFFF;
        max-width: 770px;
        width: 100%;

        @media(max-width: 480px) {
            font-size: 45px;
            line-height: 60px;
        }

        span {
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            background-image: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        }
    }

    &__subtitle {
        font-size: 32px;
        line-height: 44px;
        text-transform: lowercase;
        color: #FFFFFF;
        font-family: 'Monrope-Regular';
        margin-top: 18px;
        margin-bottom: 50px;

        @media(max-width: 480px) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    &__button {
        background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        border-radius: 4px;
        border: none;
        outline: none;
        max-width: 306px;
        width: 100%;
        padding: 20px 0;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        font-size: 20px;
        line-height: 100%;
        color: #FFFFFF;
    }


}


