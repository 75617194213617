.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transform: translateY(-200%);
    transition: transform 0.7s ease-in-out;

    &.active {
        transform: translateY(0);
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url('../../assets/images/modalBg.png') no-repeat center center /cover;
        padding: 60px;
        border-radius: 12px;

        @media(max-width: 1200px) {
            flex-wrap: wrap;
            justify-content: center;
            img {
                display: none;
            }
        }
    }

    &__content {}

    &__title {
        font-size: 50px;
        line-height: 70px;
        color: #FFFFFF;
        font-family: 'ElMessiri-Bold';
        text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        max-width: 790px;
        width: 100%;
        margin-bottom: 15px;

        @media(max-width: 1200px) {
            font-size: 30px;
            line-height: 40px;

        }
    }

    &__subtitle {
        font-size: 20px;
        line-height: 27px;
        color: #FFFFFF;
        font-family: 'Monrope-SemiBold';
        margin-bottom: 50px;
    }

    &__form {
        display: flex;
        align-items: center;

        column-gap: 30px;

        @media(max-width: 1200px) {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 15px;
        }

        .error {
            color: #FF0000;
            font-size: 18px;
            line-height: 19px;
            font-family: 'Monrope-Bold';
            margin-bottom: 10px;
        }


        input {
            padding: 20px 30px;
            display: block;
            border: none;
            background: #FFFFFF;
            border-radius: 4px;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 128%;
            color: #000000;
            font-family: 'Monrope-Regular';

            &::placeholder {
                color: #C3C3C3;
            }
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none; // Yeah, yeah everybody write about it
        }

        input[type='number'],
        input[type="number"]:hover,
        input[type="number"]:focus {
            appearance: none;
            -moz-appearance: textfield;
        }

        button {
            background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
            border-radius: 4px;
            border: none;
            outline: none;
            max-width: 365px;
            width: 100%;
            cursor: pointer;
            font-size: 20px;
            line-height: 100%;
            font-family: 'Monrope-SemiBold';
            color: #FFFFFF;
            padding: 20px;
        }
    }
}