.contact {
    background: url('../../assets/images/mapBg.png') no-repeat center bottom /cover;
    padding-top: 100px;

    &__title {
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        font-size: 50px;
        line-height: 70px;
        font-family: 'ElMessiri-SemiBold';
        margin-bottom: 50px;
    }

    &__wrapper {
        position: relative;



        // @media(max-width: 400px) {
        //     display: none;
        // }
    }

    &__info {
        position: absolute;
        top: 99px;
        right: 200px;
        background: rgba(198, 166, 106, 0.02);
        box-shadow: 0px 0px 28px rgba(2, 31, 49, 0.35);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        padding: 60px 25px;

        @media(max-width: 480px) {
            padding: 60px 10px;
            left: 0;
            right: 0;
        }


        &-title {
            color: #E3B95C;
            font-size: 40px;
            line-height: 100%;
            margin: 0px auto 40px auto;
            text-align: center;
            max-width: 195px;
            width: 100%;
            font-family: 'ElMessiri-SemiBold';

            @media(max-width: 480px) {
                font-size: 25px;
                margin-bottom: 30px;
            }
        }

        &-location,
        &-number {
            display: flex;
            align-items: center;
            column-gap: 25px;

            p {
                font-size: 24px;
                line-height: 130%;
                color: #0E2038;
                font-family: 'Monrope-Regular';
                max-width: 260px;
                width: 100%;
                margin-bottom: 50px;

                @media(max-width: 480px) {
                    font-size: 20px;
                    line-height: 100%;
                    margin-bottom: 30px;
                }
            }

            a {
                font-size: 24px;
                line-height: 130%;
                color: #0E2038;
                font-family: 'Monrope-Regular';
                max-width: 260px;
                width: 100%;

                @media(max-width: 480px) {
                    font-size: 20px;
                    line-height: 100%;
                   
                }
            }
        }

        &-link {
            display: flex;
            background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
            border-radius: 4px;
            justify-content: center;
            padding: 13px 0;
            margin-top: 90px;

            @media(max-width: 480px) {
                margin-top: 30px;
               
            }
        }
    }

}


.footer {
    background: rgba(198, 166, 106, 0.02);
    backdrop-filter: blur(10px);
    padding: 20px 0;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__socials {
        display: flex;
        align-items: center;
        column-gap: 35px;

        p {
            font-size: 24px;
            line-height: 100%;
            font-family: 'ElMessiri-Regular';
            color: #FFFFFF;
            max-width: 195px;
            width: 100%;


            @media(max-width: 480px) {
               font-size: 16px;
               
            }
        }
    }
}