@import './fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100% !important;
  // height: 300px;
}

.swiper-slide-active {
  width: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
}


/*кнопка звонка*/

.callback-bt {
  background: #38a3fd;
  border-radius: 50%;
  box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
  cursor: pointer;
  border: 2px solid transparent;
  display: block;
  height: 75px;
  width: 75px;
  text-align: center;
  position: fixed;
  right: 8%;
  bottom: 18%;
  z-index: 999;
  transition: .3s ease-in-out;
  -webkit-animation: hoverWave linear 1s infinite;
  animation: hoverWave linear 1s infinite;
  display: none;

  @media(max-width: 1200px) {
    display: block;
  }
}

.callback-bt:hover {
  background: #fff;
  border: 2px solid #38a3fd;
}

.callback-bt .text-call {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  position: relative;
}

.callback-bt .text-call:after {
  content: "";
  background: url('../assets/icons/call.svg') no-repeat center center;
  display: block;
  color: #fff;
  font-size: 38px;
  line-height: 75px;
  height: 75px;
  width: 75px;
  opacity: 1;
  transition: .3s ease-in-out;
  animation: 1200ms ease 0s normal none 1 running shake;
  animation-iteration-count: infinite;
}

.callback-bt .text-call:hover:after {
  opacity: 0;
}

.callback-bt .text-call span {
  color: #38a3fd;
  display: block;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  opacity: 0;
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .3s ease-in-out;
  font-family: 'montserrat', Arial, Helvetica, sans-serif;
}

.callback-bt .text-call:hover span {
  opacity: 1;
}

@keyframes hoverWave {
  0% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
  }

  40% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2)
  }

  80% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 26.7px rgba(56, 163, 253, 0.067)
  }

  100% {
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3), 0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0.0)
  }
}

/* animations icon */

@keyframes shake {
  0% {
      transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
  }

  10% {
      transform: rotateZ(-30deg);
      -ms-transform: rotateZ(-30deg);
      -webkit-transform: rotateZ(-30deg);
  }

  20% {
      transform: rotateZ(15deg);
      -ms-transform: rotateZ(15deg);
      -webkit-transform: rotateZ(15deg);
  }

  30% {
      transform: rotateZ(-10deg);
      -ms-transform: rotateZ(-10deg);
      -webkit-transform: rotateZ(-10deg);
  }

  40% {
      transform: rotateZ(7.5deg);
      -ms-transform: rotateZ(7.5deg);
      -webkit-transform: rotateZ(7.5deg);
  }

  50% {
      transform: rotateZ(-6deg);
      -ms-transform: rotateZ(-6deg);
      -webkit-transform: rotateZ(-6deg);
  }

  60% {
      transform: rotateZ(5deg);
      -ms-transform: rotateZ(5deg);
      -webkit-transform: rotateZ(5deg);
  }

  70% {
      transform: rotateZ(-4.28571deg);
      -ms-transform: rotateZ(-4.28571deg);
      -webkit-transform: rotateZ(-4.28571deg);
  }

  80% {
      transform: rotateZ(3.75deg);
      -ms-transform: rotateZ(3.75deg);
      -webkit-transform: rotateZ(3.75deg);
  }

  90% {
      transform: rotateZ(-3.33333deg);
      -ms-transform: rotateZ(-3.33333deg);
      -webkit-transform: rotateZ(-3.33333deg);
  }

  100% {
      transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
  }
}
/* конец кнопки звонка */