.security {
    // background: url('../../assets/images/aboutPic1.png') no-repeat center bottom /cover;
    background: #0E2038;
}

.about {
    margin-top: 50px;
    background: rgba(22, 31, 46, 0.05);
    box-shadow: 0px 0px 28px rgba(2, 31, 49, 0.35);
    border-bottom: 2px solid #E3B95C;
    margin-bottom: 50px;
    padding: 0 15px;


    &__wrapper {

        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 1400px) {
            flex-wrap: wrap;
            justify-content: center;


        }

        @media(max-width: 992px) {
            img {
                width: 100%;
            }
        }
    }

    &__content {
        max-width: 630px;
        width: 100%;
        margin: 0 auto;

        h2 {
            font-size: 50px;
            line-height: 70px;
            font-family: 'ElMessiri-SemiBold';
            color: #E3B95C;
            margin-bottom: 50px;
        }

        p {
            font-size: 20px;
            line-height: 135%;
            color: #FFFFFF;
            font-family: 'Monrope-Regular';
            margin-bottom: 50px;
           

            span {
                color: #E3B95C;
                font-family: 'Monrope-SemiBold';
            }
        }
    }
}