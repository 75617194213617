.nav {
    background: rgba(198, 166, 106, 0.02);
    backdrop-filter: blur(10px);
    padding: 20px 0;
    z-index: 99;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__list {
        display: flex;
        column-gap: 60px;

        &-link {
            font-family: 'Monrope-Regular';
            font-size: 18px;
            line-height: 110%;
            font-feature-settings: 'case'on;
            color: #FFFFFF;


            &.active {
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;
                background-image: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
            }

        }
    }

    &__contact {
        display: flex;
        align-items: center;
        column-gap: 50px;
    }

    &__call {
        display: flex;
        flex-direction: column;
        background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        padding: 8px 19px;
        font-size: 20px;
        line-height: 27px;
        font-family: 'Monrope-Regular';
        color: #FFFFFF;

        span {
            font-size: 13px;
            line-height: 18px;
        }

        @media (max-width: 400px) {
            display: none;
        }
    }

    &__switcher {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 20px;
        line-height: 27px;
        font-family: 'Monrope-Regular';
        color: #FFFFFF;
    }


    &__btn {
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 50%;
        border: none;
        outline: none;
        position: relative;
        display: none;

        &-burger,
        &-burger::before,
        &-burger::after {
            position: absolute;
            width: 24px;
            height: 2px;
            background: #222;
            top: 50%;
            margin-top: -1px;
            left: 50%;
            margin-left: -12px;
            cursor: pointer;
        }

        &-burger::before,
        &-burger::after {
            content: '';
            display: block;
        }

        &-burger::before {
            transform: translateY(-5px);
        }

        &-burger::after {
            transform: translateY(5px);
        }

        &.active {
            .nav__btn-burger::before {
                transform: rotate(45deg);
                transition: 0.5s;
            }

            .nav__btn-burger::after {
                transform: rotate(-45deg);
                transition: 0.5s;

            }

            .nav__btn-burger {
                height: 0;
            }
        }

    }
}


@media (max-width: 992px) {
    .nav__btn {
        display: block;
    }

    .nav__list {
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        display: none;
        height: 100vh;
        
        &-link {
            margin: 10px;
        }

        &.active {
            display: flex;
            row-gap: 20px;
        }

      
    }

   

  

}