.complex {
    background: #0E2038;

    &__title {
        font-size: 50px;
        line-height: 70px;
        font-family: 'ElMessiri-Bold';
        box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(67.5px);
        text-align: center;
        padding: 30px 0;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);

        @media(max-width: 480px) {
            font-size: 30px;
            line-height: 40px;
        }
    }


    &__content {
        // background: url('../../assets/images/complex.png') no-repeat center center /cover;
        min-height: 827px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        // overflow-y: scroll;

        &-img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            object-fit: cover;



            @media(max-width: 1570px) {
               object-fit: contain;
            }
            @media(max-width: 768px) {
               object-fit: cover;
            }
            @media(max-width: 575px) {
               object-fit: contain;
            }
        }

        @media(max-width: 768px) {
            min-height: 300px
        }
        @media(max-width: 375px) {
            min-height: 200px
        }
    }

    &__btn {
        background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        box-shadow: 0px 0px 30px 6px rgba(248, 165, 41, 0.25);
        backdrop-filter: blur(10px);
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 16px 60px;
        cursor: pointer;
        color: #FFFFFF;
        text-shadow: 0px 1px 2px rgba(179, 135, 70, 0.5);
        font-size: 32px;
        line-height: 100%;
        font-family: 'ElMessiri-Bold';
        position: absolute;


        &:nth-of-type(1) {
            right: 10%;
            top: 50%;
        }

        &:nth-of-type(3) {
            left: 1%;
            top: 50%;
        }


        @media(max-width: 992px) {
            padding: 10px 30px;
            font-size: 18px;
        }

        @media(max-width: 575px) {
            padding: 10px 15px;
            font-size: 14px;
        }
    }
}