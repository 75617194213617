.planModal {
    width: 100%;
    background: #0E2038;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 99;
    overflow-y: scroll;
    display: none;

    &.active {
        display: block;
    }


    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            color: #fff;
            font-size: 50px;
            cursor: pointer;
            margin-right: 10px;
            margin-top: 10px;
            user-select: none;
            font-family: Arial;

            @media(max-width: 992px) {
                font-size: 25px;
            }

            @media(max-width: 420px) {
                font-size: 25px;
                margin-top: 0px;
            }
        }

        h2 {
            margin: 0 auto;
            color: #fff;
            font-size: 50px;
            font-family: Arial;
            margin-bottom: 20px;

            @media(max-width: 992px) {
                font-size: 25px;
            }

            @media(max-width: 420px) {
                font-size: 20px;
            }
        }

    }

    &__formBlock {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 50px;

        h4 {
            color: white;
            font-family: Arial;
            font-size: 25px;
            text-align: center;
        }
    }

    &__radio {

        input[type=radio] {
            display: none;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding-left: 25px;
            margin-right: 0;
            line-height: 18px;
            user-select: none;

            span {
                color: white;
                font-size: 18px;
                font-family: Arial;
            }
        }


        label::before {
            content: '';
            display: inline-block;
            width: 17px;
            height: 18px;
            position: absolute;
            left: 0;
            bottom: 1px;
            border: 1px solid yellow;
            border-radius: 50%;
        }

        input[type=radio]:checked+label::before {
            background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        }


    }






    &__block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @media(max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 420px) {
            grid-template-columns: repeat(1, 1fr);
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;

            img {
                width: 100%;
            }

            button {
                background: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
                border-radius: 4px;
                border: none;
                outline: none;
                max-width: 365px;
                width: 100%;
                cursor: pointer;
                font-size: 20px;
                line-height: 100%;
                font-family: 'Monrope-SemiBold';
                color: #FFFFFF;
                padding: 20px;

                @media(max-width: 768px) {
                    padding: 10px;
                    font-size: 18px;
                }
            }
        }
    }
}