.videoBlock {
    background: url('../../assets/images/videoBlockBg.png') no-repeat center center /cover;
    padding-bottom: 100px;

    &__title {
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(260.06deg, #D19C1D 0%, #D5A22B 16.9%, #CA9618 33.25%, #D19C1D 48.05%, #E0AB2F 62.4%, #D8AA3F 76.86%, #F1C666 91.61%, #E3B95C 101.38%);
        font-size: 50px;
        line-height: 70px;
        font-family: 'ElMessiri-SemiBold';
        margin-bottom: 50px;
    }

    &__video {
        min-height: 600px;
        width: 100%;
    }


}



@media(max-width: 768px) {
    .videoBlock {
        &__video {
            min-height: 100%;
        }
    }
}